<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">系统监控</el-breadcrumb-item>
      <el-breadcrumb-item>操作日志</el-breadcrumb-item>
      <el-breadcrumb-item>核校详细日志</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input
            placeholder="请输入用户名进行搜索"
            v-model="queryinfo.query"
            clearable
            size="small"
          >
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
      </el-row>
      <el-row>
        <br />
        <!-- table 表格区域
        <el-button type="primary" size="mini" icon="fa fa-check" plain
          >&nbsp;批量置为无效</el-button
        >-->
      </el-row>
      <!-- table 表格区域 -->
      <el-table :data="goodsList" border stripe>
        <el-table-column type="index" label="#"></el-table-column>
        <!-- table 扩展区域 -->
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item label="创建时间">
                <span>{{ props.row.createTime }}</span>
              </el-form-item>
              <br />
              <el-form-item label="更新时间">
                <span>{{ props.row.updateTime }}</span>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column label="用户名" prop="nickname"></el-table-column>
        <el-table-column
          label="操作模块"
          prop="optModule"
          width="100px"
        ></el-table-column>
        <el-table-column
          label="操作类型"
          prop="optType"
          width="100px"
        ></el-table-column>
        <el-table-column
          label="操作URL"
          prop="optUrl"
          width="190px"
        ></el-table-column>
        <el-table-column
          label="操作方法"
          prop="optMethod"
          width="300px"
        ></el-table-column>
        <el-table-column
          label="操作描述"
          prop="optDesc"
          width="220px"
        ></el-table-column>
        <el-table-column
          label="操作ip"
          prop="ipAddress"
          width="120px"
        ></el-table-column>
        <el-table-column
          label="操作地址"
          prop="ipSource"
          width="150px"
        ></el-table-column>
        <el-table-column
          label="请求方式"
          prop="requestMethod"
          width="80px"
        ></el-table-column>
        <el-table-column fixed="right" label="请求参数" prop="" width="82px">
          <template slot-scope="scope">
            <el-button
              type="success"
              size="mini"
              icon="fa fa-eye"
              plain
              circle
              @click="showReason(scope.row.requestParam)"
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="响应参数" prop="" width="82px">
          <template slot-scope="scope">
            <el-button
              type="success"
              size="mini"
              icon="fa fa-eye"
              plain
              circle
              @click="showReason(scope.row.responseData)"
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column v-if="false" fixed="right" label="操作" width="180px">
          <el-button
            type="primary"
            size="mini"
            icon="fa fa-pencil"
            plain
            circle
            @click="showEditDialog(scope.row.goodsId)"
          ></el-button>
        </el-table-column>
      </el-table>

      <!-- 页面分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pagenum"
        :page-sizes="[20,50, 70, 100, 120]"
        :page-size="queryinfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'verifyLog',
  data() {
    return {
      // 查询参数对象
      queryinfo: {
        query: '',
        pagenum: 1,
        pagesize: 50
      },
      goodsList: [],
      total: 0,
      visible: false,
      lists: []
    }
  },
  methods: {
    showReason(description) {
      this.$alert(description, '详情', {
        confirmButtonText: '确定',
        callback: action => {}
      })
    },
    // 监听开关状态的改变
    async usersChanged(userInfo) {
      const { data: res } = await this.$http.put(
        `stars/${userInfo.couId}/state/${userInfo.status}`
      )
      if (res.code !== '20000') {
        userInfo.status = !userInfo.status
        return this.$message.error('更新状态失败!')
      }
      this.$message.success('更新状态成功！')
    },
    // 根据分页获取商品列表的全部数据
    async getGoodList() {
      const { data: res } = await this.$http.get('verifyList', {
        params: this.queryinfo
      })
      if (res.code !== '20000') {
        this.$message.error(res.message)
        return
      }
      this.$message.success('获取评价列表成功！')
      console.log(res.data)
      this.goodsList = res.data.lists
      this.total = Number(res.data.total)
    },
    handleSizeChange(newSize) {
      this.queryinfo.pagesize = newSize
      this.getGoodList()
    },
    handleCurrentChange(newPage) {
      this.queryinfo.pagenum = newPage
      this.getGoodList()
    },
    addCoupon() {}
  },
  mounted() {},
  created() {
    this.getGoodList()
  }
}
</script>

<style lang="less" scoped></style>
